import {Inject} from "@angular/core";
import { Store } from "@ngrx/store";

import {IData} from "../layout/cobranza-digital/store/states/autenticated.state";
import {ICustomerData} from "../layout/cobranza-digital/store/states/alternativesCache.state";
import {IAlternatives} from "../layout/cobranza-digital/store/states/alternatives.state";
import { IClientOk, initialStateClientOk as errDEF } from '../layout/cobranza-digital/store/states/clientOk.state';
import {pinTest} from "./pinblockTest";
import Bowser from "bowser";
import { LoadAuthorize } from '../layout/cobranza-digital/store/actions/authorize.action';
import { LoadInCacheAlternativesFailed, LoadInCacheAlternativesSuccess } from "../layout/cobranza-digital/store/actions/alternativeInCache.action";
import { LoadAlternativeIndex, LoadAlternativeSelected, LoadAlternativesFailed, LoadAlternativesSuccess } from "../layout/cobranza-digital/store/actions/alternatives.action";
import { LoadAutenticatedFailed, LoadAutenticatedSuccess } from "../layout/cobranza-digital/store/actions/autenticated.action";
import { IAppState } from "../store/states/app.state";
import { LOCATION_TOKEN } from "../utils/location";

// funcion para setear campo de periodicidad segun el campo newAmortizationDescription (API - Alternativas)
export const setPeriodicity = (value: string): string => {
	const periodicidad = value.toLocaleUpperCase();

	if (periodicidad === "MENSUAL") {
		value = "Mensuales";
	}

	if (periodicidad === "BIMESTRAL") {
		value = "Bimestrales";
	}

	if (periodicidad === "TRIMESTRAL") {
		value = "Trimestrales";
	}

	if (periodicidad === "CUATRIMESTRAL") {
		value = "Cuatrimestrales";
	}

	if (periodicidad === "SEMESTRAL") {
		value = "Semestrales";
	}

	if (periodicidad === "ANUAL") {
		value = "Anuales";
	}

	return value;
};

// funcion para dar formato a la fecha como se desea en la modal
// cancelar o salir --> en este momento Stoc esta en inglis
export const months = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE"
];

export const transformMonth = (month: string) => {
  let transformedMonth = month;
  months.forEach((m) => {
    if (month.toUpperCase() === m) {
      transformedMonth = (months.indexOf(month.toUpperCase()) + 1).toString();
    }
  });
  return transformedMonth;
};

export const transformYear = (year: string) => {
  const NUMBER_TWO: number = 2;
  return year.slice(NUMBER_TWO, year.length);
};

export const padSingleDigitMonth = (month: string) => {
  if (month.length === 1) {
    month = `0${month}`;
  }
  return month;
};

export const transformDateExpire = (date: string) => {
  const two = 2;
  const monthDayYear = date.split(" ").filter((e) => e.toUpperCase() !== "DE");

  const transformedMonth = transformMonth(monthDayYear[0]);
  const day = monthDayYear[1];
  const transformedYear = transformYear(monthDayYear[two]);

  const finalDate = [day, transformedMonth, transformedYear];

  finalDate[1] = padSingleDigitMonth(finalDate[1]);

  return finalDate.join("/");
};

// funcion para separar horas minutos y segundos
export const splitHour = (time: string) => {
	let hourAux;
	let hours;
	let minutes;
	let seconds;
	const NUMBER_TWO: number = 2;

	if (time !== undefined) {
		hourAux = time.split(":");
		hours = Number(hourAux[0]);
		minutes = Number(hourAux[1]);
		seconds = Number(hourAux[NUMBER_TWO]);
	}

	return {
		hours,
		minutes,
		seconds
	};
};

export const msAddcero = (num: number) : string => {
  const diez = 10;
  return (num < diez) ? (`0${num}`) : (""+num);
}

export const pasoFuncional = (key : number) : string => {
  const paso = [
    "Listado_Alternativas",
    "Nuevas_condiciones",
    "Aceptar_TyC",
    "Aceptar_politica",
    "Actualiza_contacto",
    "Confirmar_numero",
    "Agendamiento",
    "Recibimos_tu_numero"
  ];
  if((key >= 0) && (key < paso.length) ){
    return paso[key];
  } else{
    return "";
  }
}


export const LOG2A = 0;
export const LOG2B = 1;
export const LOG3A = 2;
export const LOG3B = 3;
export const LOG4A = 4;
export const LOG5A = 5;
export const LOG5B = 6;
export const LOG6A = 7;
export const LOG3C = 8;

export const urlLogs = (log : string) : string => {
  const logsUrl = new Map();
  logsUrl.set("logs",'ms-logs/register');
  logsUrl.set("auth",'ms-auth/logFunctional');
  logsUrl.set("offer",'ms-offer/logFunctional');
  logsUrl.set("client",'ms-client/logFunctional');
  if(logsUrl.get(log) != undefined){
    return logsUrl.get(log)
  }else{
    return "";
  }
}

export const typeDcmt = (log : string) : string => {
  const logsUrl = new Map();
  logsUrl.set("CD","0");
  logsUrl.set("CC","1");
  logsUrl.set("CE","2");
  logsUrl.set("NI","3");
  logsUrl.set("TI","4");
  logsUrl.set("PP","5");
  logsUrl.set("IN","6");
  logsUrl.set("IJ","7");
  logsUrl.set("FI","8");
  logsUrl.set("RC","9");
  if(logsUrl.get(log) != undefined){
    return logsUrl.get(log)
  }else{
    return "";
  }
}

export const pinblocksGen = (id : string) : string => {
  if(pinTest.get(id) != undefined){
    return pinTest.get(id)
  }else{
    return "";
  }
}



export const disp = (dat : string) : string => {
  switch (dat) {
    case "tablet":
        return "Tablet";
    case "mobile":
        return "Mobile";
    default:
        return "PC";
  }
}

export const momento = () : string =>{
  const dy = horaColombia();
  let fechaC : string = "";
  fechaC += `${msAddcero(dy.getDate())}/${msAddcero(dy.getMonth()+1)}/${dy.getFullYear()}`;
  fechaC += ` ${msAddcero(dy.getHours())}:${msAddcero(dy.getMinutes())}:${msAddcero(dy.getSeconds())}`;
  return fechaC;
}

export const horaColombia = () : Date =>{
  const dateDispositivo = new Date();
  const convertDateColombia =  dateDispositivo.toLocaleString('en-US', { timeZone: "America/Bogota" });
  const dy = new Date(convertDateColombia);
  return dy;
}

export const errNull = (url : string, err : IClientOk = errDEF) : IClientOk =>{
  const serviceFail = 503;
  const ex : IClientOk= {
    error : {
      text: "Fail",
      message: err.message,
      errorCodeHTTP: `${err.status}`,
      errorNameHTTP: err.name,
      errorDescription: "Servicio caido",
      errorDetails: {
        exceptionType: "",
        functionalErrorCode: `${url.toUpperCase()}500`,
        functionalErrorDescription: `${url.toUpperCase()}500`,
        operationName: "",
        serviceName: url.toUpperCase()
      }
    },
    message: err.message,
    name: err.name,
    ok: err.ok,
    status: err.status,
    statusText: err.statusText
  };

  if(err.error === null || err.status === serviceFail){
    err = ex;
  }
  return err;
}

export const armBody = (dat : IData, dat2 : any, paso : string,  err : IClientOk = errDEF) : any => {
  const memento : string = momento();
  const bow = Bowser.getParser(navigator.userAgent);
  const canal = sessionStorage.getItem("trace");
  return {
    technicalSection:{
        idSesion: dat.sessionId,
        ipClient: dat.ipClient,
        fechaHoraTransaccion: memento,
        pasoFuncional: paso,
        canal: canal !== null ? "SITIO_CONTENIDOS" :  "APP_PERSONAS",
        deviceBrowser: bow.getBrowserName(),
        sistemaOperativo: bow.getOSName(),
        dispositivo: disp(bow.getPlatformType())
    },
    clientSection:{
        tipoDocumentoId: typeDcmt(dat.documentType),
        numeroDocumento: dat.documentNumber,
        nombreCortoCliente: dat2.customerName,
        telefonoCelular: dat2.newMobilePhone !== "" && dat2.newMobilePhone !== undefined
         ? dat2.newMobilePhone : dat2.mobilePhone
    },
    errorSection:{
        codigoError: err.error.errorDetails.functionalErrorCode,
        descripcionError: err.error.errorDescription,
        codigoErrorFuncional: err.error.errorDetails.functionalErrorCode,
        descripcionErrorFuncional:err.error.errorDetails.functionalErrorDescription,
        tipoExcepcion: validarTyC(err.error.errorDetails.exceptionType, paso),
        servicio:err.error.errorDetails.serviceName,
        operacionServicio: "CD_UNIFICACION_CONTACTO_INM"
    }
  }
}

export const validarTyC = (exceptionType : string, paso : string) : string =>{
  let tipoExcepcion = "";
  if(exceptionType === "" && paso.includes("Confirmar_numero")){
    tipoExcepcion = "Aceptar_TyC";
  }else if (exceptionType !== ""){
    tipoExcepcion = "Técnico";
  }
  return tipoExcepcion;
}

export const generateLog = (paso: number,  dat : IData, dat2 : ICustomerData,err : IClientOk = errDEF) => {
  const bodyLogs : any = armBody(dat, dat2, pasoFuncional(paso), err);
  return {bodyLogs};
}



export const mes = (mesM : number) : string =>{
  if(mesM < months.length && mesM >=0){
    return months[mesM];
  }else{
    return "";
  }
}


export const formatLoanNumber = (loanNumber: string) => {
  let formattedLoanNumber = "";
  for (const char of loanNumber) {
    if (char != '*') {
      formattedLoanNumber += char;
    }
  }
  return formattedLoanNumber;
};

export const formatOfferDetails = (offerDetails: any) => {
  let formattedDetails = "";
  const creditsIncluded = offerDetails.creditsIncluded.length;
  for (let j = 0; j < creditsIncluded; j++) {
    const loanNumber = offerDetails.creditsIncluded[j].loanNumber;
    formattedDetails += formatLoanNumber(loanNumber);
    if (j < creditsIncluded - 1) {
      formattedDetails += "-";
    }
  }
  formattedDetails += `, ${offerDetails.newConditions.term} meses de plazo y ${offerDetails.newConditions.gracePeriod} meses de periodo de gracia)`;
  return formattedDetails;
};

export const oblT = (obl: IAlternatives): string => {
  let obls: string = "";
  for (let i = 0; i < obl.offerList.length; i++) {
    obls += "(obl: ";
    obls += formatOfferDetails(obl.offerList[i].details);
    if (i < obl.offerList.length - 1) {
      obls += " / ";
    }
  }
  return obls;
};

export const armBodySQS = (dat : IData, dat2 : IAlternatives) : any => {
  const dy = horaColombia();
  return {
    documentNumber: dat.documentNumber,
    documentType: typeDcmt(dat.documentType),
    contactType: "ENT",
    managementType: "DIG",
    managementCode : "434",
    fecha_gestion: `${dy.getFullYear()}-${msAddcero(dy.getMonth()+1)}-${msAddcero(dy.getDate())}`,
    hora_gestion: `${msAddcero(dy.getHours())}:${msAddcero(dy.getMinutes())}:${msAddcero(dy.getSeconds())}`,
    telephoneNumber: dat2.customerData.newMobilePhone !== "" && dat2.customerData.newMobilePhone !== undefined ?
    dat2.customerData.newMobilePhone : dat2.customerData.mobilePhone ,
    note: `${dat2.customerData.alternativeType} ${oblT(dat2)}`,
    loans: dat2.customerData.obligaciones,
    tapeRecorder: "ContactoInmediato"
  }
}


@Inject({
  providers: [
		{ provide: LOCATION_TOKEN, useValue: window.location }
	]
})

export class ChangeGuard{
  constructor(
    public _store: Store<IAppState>,
    @Inject(LOCATION_TOKEN) public location: Location
  ) {}

  logoutItems(a: string = "", b: string = "") {
    localStorage.setItem("auth", "false");
    localStorage.removeItem("auth");
    localStorage.clear();
    localStorage.removeItem(a);
    localStorage.removeItem(b);
    sessionStorage.clear();
    sessionStorage.removeItem(a);
    sessionStorage.removeItem(b);
    this._store.dispatch(new LoadAuthorize(false));
    this._store.dispatch(new LoadAuthorize(false));
    this._store.dispatch(new LoadAutenticatedFailed(false));
    this._store.dispatch(new LoadInCacheAlternativesFailed(false));
    this._store.dispatch(new LoadAlternativesFailed(false));
    this._store.dispatch(new LoadAutenticatedSuccess(false));
    this._store.dispatch(new LoadInCacheAlternativesSuccess(false));
    this._store.dispatch(new LoadAlternativesSuccess(false));
    this._store.dispatch(new LoadAlternativeSelected(false));
    this._store.dispatch(new LoadAlternativeIndex(false));
    let url : string = window.location.href;
    let n : number = url.length;
    for (let i = url.length; i >= 0 ; i--){
      n = i;
      if(url.charAt(n) === '/'){
        break;
      }
    }
    url = url.slice((0),(n))
    url +=  "?code=" + "logout";
    this.location.assign(url);
  }

}
