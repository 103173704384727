import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IAlternatives } from "../layout/cobranza-digital/store/states/alternatives.state";
import { IAlternativesCache } from "../layout/cobranza-digital/store/states/alternativesCache.state";
import { IClientOk } from "../layout/cobranza-digital/store/states/clientOk.state";
import {
	  IData,
	initialStateIData,
	IAutenticated,
  IAutenticatedRedirection
} from "../layout/cobranza-digital/store/states/autenticated.state";

import { typeDcmt } from "../utils/utils";

@Injectable({
  providedIn: "root"
})
export class CobranzaService {
	public endpoint: string = environment.apiEndPointCobranza;
	public url: string = environment.urlServices;

	bodyClient = {
		documentType: "",
		documentNumber: ""
	};

	bodyOffer = {
		documentType: 0,
		documentNumber: 0
	};

	bodyAuth = {
		data: [
			{
				header: {
					id: "12345",
					type: "string"
				},
				authorization_code: ""
			}
		]
	};

	bodyGenerateCodeAuth = {
		data: [
			{
				idNumber: "",
				documentType: "CC",
				pinBlock: ""
			}
		]
	};

	constructor(private http: HttpClient) {}

	getHeader = (tokenInput: string = "", tokenFua: string = "") => {
		return new HttpHeaders({
			"Content-Type": "application/json",
			"Content-Encoding": "gzip, deflate, br",
			jwtInput: tokenInput,
			jwtFua: tokenFua
		});
	};

	/**
	 * Error para cualquier servicio:
	 * Copy return: {
   *   return this.http.get<any>(
        `https://run.mocky.io/v3/16eb7eb7-e319-4bec-abf3-1e582377aa85`
      ); //ErrorGeneral
   * }
   *
   * https://run.mocky.io/v3/e9768691-c92f-405a-b6f2-3d9792767109
   * -> Una sola altenativa
   *
   * https://run.mocky.io/v3/77ff8b58-e49e-4ca6-ad3a-7a6fbb40212e
   * -> Dos alternativas
   *
   * https://run.mocky.io/v3/f9bd0345-d186-428f-bd92-3015eef8632d Cel defectuoso
	 */

	getAlternatives(dat: IData = initialStateIData): Observable<IAlternatives> {
		this.bodyOffer.documentNumber = +dat.documentNumber;
		this.bodyOffer.documentType = +typeDcmt(dat.documentType);
		return this.http.post<IAlternatives>(
			`${this.url}/ms-offer/portfolioOffers`,
			JSON.stringify(this.bodyOffer),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		); // para hacer pruebas en local hay que comentar esta linea [LOCALPRUB]
		// return this.http.get<any>(
		//   `https://run.mocky.io/v3/77ff8b58-e49e-4ca6-ad3a-7a6fbb40212e`
		// ); // para hacer pruebas en local hay que habilitar esta linea [LOCALPRUB]
	}

	getClientServiceOK(dat: IData = initialStateIData): Observable<IClientOk> {
		this.bodyClient.documentNumber = `${dat.documentNumber}`;
		this.bodyClient.documentType = typeDcmt(dat.documentType);
		return this.http.post<IClientOk>(
			`${this.url}/ms-client/client`,
			JSON.stringify(this.bodyClient),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		);
	}

	getSaveInCacheAlternativesService(
		dat: IData = initialStateIData
	): Observable<IAlternativesCache> {
		this.bodyOffer.documentNumber = +dat.documentNumber;
		this.bodyOffer.documentType = +typeDcmt(dat.documentType);
		return this.http.post<IAlternativesCache>(
			`${this.url}/ms-offer/offer`,
			JSON.stringify(this.bodyOffer),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		); // para hacer pruebas en local hay que comentar esta linea [LOCALPRUB]
		// return this.http.get<any>(
		//   `https://run.mocky.io/v3/77ff8b58-e49e-4ca6-ad3a-7a6fbb40212e`
		// ); // para hacer pruebas en local hay que habilitar esta linea [LOCALPRUB]
	}

	clientStatusUpdate(op: string, dat: IData): Observable<string> {
		const bodyStatusUp = {
			opportunityId: op
		};
		return this.http.post<string>(
			`${this.url}/ms-offer/statusUpdate`,
			JSON.stringify(bodyStatusUp),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		);
	}

	/**
	 * AUTH mock: https://run.mocky.io/v3/206e2503-ebcb-4b35-b002-4cf861754cd6
	 */
	getAutenticate(code: string = ""): Observable<IAutenticated> {
		this.bodyAuth.data[0].authorization_code = code;
		return this.http.post<IAutenticated>(
			`${this.url}/ms-auth/authorize`,
			JSON.stringify(this.bodyAuth),
			{
				headers: this.getHeader()
			}
		);
	}

	generateAuthCode(
		cc: string,
		pinBlock: string,
		typeDc: string
	): Observable<any> {
		this.bodyGenerateCodeAuth.data[0].idNumber = cc;
		this.bodyGenerateCodeAuth.data[0].pinBlock = pinBlock;
		this.bodyGenerateCodeAuth.data[0].documentType = typeDc;

		return this.http.post<any>(
			`${this.url}/ms-auth/codes`,
			JSON.stringify(this.bodyGenerateCodeAuth),
			{
				headers: this.getHeader()
			}
		);
	}

	getContactService(dat: IData): Observable<any> {
		this.bodyOffer.documentNumber = +dat.documentNumber;
		this.bodyOffer.documentType = +typeDcmt(dat.documentType);
		return this.http.post(
			`${this.url}/ms-offer/portfolioOffers`,
			this.bodyOffer,
			{
				headers: this.getHeader(
					dat.transactionToken,
					dat.transactionToken
				),
				observe: "response"
			}
		);
	}

	getLogoutService(accTk: string, dcmt: string): Observable<any> {
		const losgaut: any = {
			accessToken: accTk,
			documentNumber: dcmt
		};

		return this.http.post(`${this.url}/ms-auth/logOut`, losgaut, {
			headers: this.getHeader(),
			observe: "response"
		});
	}

	setAdminfo(bodySQS: any, dat: IData): Observable<any> {
    return this.http.post(`${this.url}/ms-offer/sendsqs`, bodySQS, {
			headers: this.getHeader(dat.transactionToken, dat.sessionToken)
		});
	}

  setAdminfoCI(bodySQS: any, dat: IData): Observable<any> {
    return this.http.post(`${this.url}/ms-signature/sign/sendsqs`, bodySQS, {
      headers: this.getHeader(dat.transactionToken, dat.sessionToken)
    });
  }

	logs(bodyLogs: any,  dat: IData): Observable<object> {
		return this.http.post<object>(
			`${this.url}/ms-logs/register`,
			JSON.stringify(bodyLogs),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		);
	}

  routing(code: string = ""): Observable<IAutenticatedRedirection> {
    const codeBody = {
			"authorization_code": code
		};
		return this.http.post<IAutenticatedRedirection>(
			`${this.url}/routing/consult`,
			codeBody
		);
	 }

   getAdminfo(bodyLogs: any, dat: IData){
    return this.http.post<object>(
      `${this.url}/ms-offer/consultHouse`,
      //`${this.contdev}/${ur}`,
      JSON.stringify(bodyLogs),
      {
        headers: this.getHeader(dat.transactionToken, dat.sessionToken)
      }
    );
   }

}

