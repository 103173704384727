import { Component, Inject, OnInit } from "@angular/core";
import { DataModal } from "src/assets/interfaces/interfaces.state";
import { DATA_MODAL_TOMATE_TIEMPO_SALIR } from "src/app/core/components/modal/constants";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { transformDateExpire, ChangeGuard } from "src/app/utils/utils";
import { CobranzaService } from "src/app/services/cobranza.service";
import { Router } from "@angular/router";
import { IClientOk } from "../../../layout/cobranza-digital/store/states/clientOk.state";
import { LOCATION_TOKEN } from "../../../utils/location";
import { IData } from "../../../layout/cobranza-digital/store/states/autenticated.state";
import { LoadClientOkFailed } from "../../../layout/cobranza-digital/store/actions/clientOk.action";
import { DOCUMENT } from "@angular/common";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
	providers: [{ provide: LOCATION_TOKEN, useValue: window.location }]
})
export class NavbarComponent implements OnInit {
	dataxModal4: DataModal = DATA_MODAL_TOMATE_TIEMPO_SALIR;
	de: string = "hola";
	expireDate: string = "";
	openN: boolean = false;
	openConfirm: boolean = false; // Variable que abre el modal Confirmar
	guard: ChangeGuard;
  dark: boolean = false;
  currentURL :string;
  

	constructor(
		//@Inject('Window') private window: Window,
		private cobranzaService: CobranzaService,
		private router: Router,
		private _store: Store<IAppState>,
		@Inject(LOCATION_TOKEN) public location: Location,
		@Inject(DOCUMENT) document: any
	) {
		this.guard = new ChangeGuard(this._store, this.location);
	}


	ngOnInit(): void {
		this.getAlternatives();
		this.currentURL = document.location.href;
	}

	cerrarModalConfirmar(event: boolean) {
		if (event) {
			this.openN = false;
		}
	}
	getAlternatives() {
		this._store
			.select((state) => state.alternatives)
			.subscribe((response) => {
        if(response.customerData.expirationDate){
          this.expireDate = transformDateExpire(
            response.customerData.expirationDate
          );
          this.dataxModal4.tex1Part2 = `estarán esperando por ti hasta el ${this.expireDate}`;
        }

			});
	}

	logout() {
		let a, c;
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((response: IData) => {
				a = response.sessionToken;
				c = response.documentNumber;
			});
		this.cobranzaService.getLogoutService(a, c).subscribe(
			() => {
				this.openN = false;
				this.guard.logoutItems(a, c);
			},
			(e: IClientOk) => {
				this._store.dispatch(new LoadClientOkFailed(e));
				this.router.navigate(["/error"]);
			}
		);
	}

	abrirModal() {
      this.openN = true;
	}

  get hiddenNav(): boolean{
		const currentURL = document.location.href;
		const urls = ["firmaelectronica","aterrizaje","detalle","ten-en-cuenta","te-llamamos","numero"]
		if (urls.some(url => currentURL.includes(url))) {
			this.dark = true;
		  }
    const hiddenNavbar = [
      "beneficiarios",
      "asegurabilidad",
      "aceptar-documento"
    ];
    let yes = true;
    hiddenNavbar.forEach((element) => {
      if (currentURL.includes(element) && yes) {
        yes = false;
      }
    });
    return yes;
  }
}
